import * as React from "react";

import { Bar } from "./bar";
import { BottomLabel } from "./bottom-label";
import { Text } from "./text";

const yPad = 70;
const xRightPad = 100;
const barMargin = 30;
const barWidth = 150;

const height = 450;
const width = 500;

const xLeftPad = 100;

type StockedBar = {
	color: string;
	label: string;
	value: number | undefined;
};

export type StokedBarGroup = {
	bars: StockedBar[];
	label: string;
	total: number | undefined;
};

type Props = {
	barGroups: StokedBarGroup[];
	lineValue: number;
	max: number;
	step: number;
};

export const AdCopyStockedBarGraph = React.memo((props: Props) => {
	const { barGroups, lineValue, max, step } = props;

	const areaWidth = xLeftPad + (barWidth + barMargin * 2) * barGroups.length + xRightPad;

	const BaseLineGroup = React.useMemo(() => {
		const tmp: JSX.Element[] = [];

		let v = 0;

		while (v <= max) {
			const y = height - yPad - (v * (height - yPad * 2)) / max;

			tmp.push(
				<g key={v}>
					<Text
						//
						fontSize="13px"
						value={v.toFixed(1)}
						x={xLeftPad - 30}
						y={y}
					/>

					<line
						//
						stroke="#ccc"
						x1={xLeftPad - 10}
						x2={xLeftPad + 10}
						y1={y}
						y2={y}
					/>

					<line
						stroke="#ccc"
						strokeDasharray="3"
						style={{
							opacity: 0.4,
						}}
						x1={xLeftPad - 10}
						x2={areaWidth - xRightPad}
						y1={y}
						y2={y}
					/>
				</g>,
			);

			v = Math.floor(v * 100 + step * 100) / 100;
		}

		return tmp;
	}, [areaWidth, max, step]);

	const Bars = React.useMemo(
		() =>
			barGroups.map((barGroup, groupIndex) => {
				let y = height - yPad;

				const x = xLeftPad + barMargin + (barMargin + barWidth) * groupIndex;

				const { total } = barGroup;

				const totalY = y - (total * (height - yPad * 2)) / max - 20;

				const textX = x + barWidth / 2;

				return (
					<React.Fragment key={groupIndex}>
						{/* グラフ下部のラベル */}
						<BottomLabel
							//
							value={barGroup.label}
							x={textX}
							y={height - 40}
						/>

						{barGroup.bars.map((bar, index) => {
							if (bar.value == null) {
								return "";
							}

							const start = y;

							const tmpHeight = (bar.value * (height - yPad * 2)) / max;

							y -= tmpHeight;

							return (
								<Bar
									key={`${groupIndex}-${index}`}
									color={bar.color}
									height={tmpHeight}
									value={bar.value.toFixed(1)}
									width={barWidth}
									x={x}
									y={start - tmpHeight}
								/>
							);
						})}

						{total != null && (
							<Text
								//
								fontSize="15px"
								value={total.toFixed(1)}
								x={textX}
								y={totalY}
							/>
						)}
					</React.Fragment>
				);
			}),
		[barGroups, max],
	);

	const Line = React.useMemo(() => {
		const y = height - yPad - (lineValue * (height - yPad * 2)) / max;

		return (
			<g>
				<line
					//
					stroke="red"
					strokeOpacity={0.8}
					strokeWidth={2}
					x1={xLeftPad}
					x2={areaWidth - xRightPad}
					y1={y}
					y2={y}
				/>

				<Text
					//
					fontSize="15px"
					textAnchor="start"
					value="目標値（万個）"
					x={areaWidth - xRightPad - 50}
					y={y - 15}
				/>
			</g>
		);
	}, [areaWidth, lineValue, max]);

	return (
		<svg
			//
			height={Math.round((width / areaWidth) * height)}
			viewBox={`0 0 ${areaWidth} ${height}`}
			width={width}
		>
			<Text
				//
				fontSize="13px"
				value="（年間数量：単位　万個）"
				x={xLeftPad}
				y={50}
			/>

			<line
				//
				stroke="#ccc"
				x1={xLeftPad}
				x2={areaWidth - xRightPad}
				y1={height - yPad}
				y2={height - yPad}
			/>

			<line
				//
				stroke="#ccc"
				x1={xLeftPad}
				x2={xLeftPad}
				y1={yPad}
				y2={height - yPad}
			/>

			{BaseLineGroup}

			{Bars}

			{Line}
		</svg>
	);
});
