import * as React from "react";

type Props = {
	value: string;
	x: number;
	y: number;
};

export const BottomLabel = React.memo((props: Props) => {
	const { value, x, y } = props;

	return (
		<text
			//
			dominantBaseline="central"
			fontSize="15px"
			textAnchor="middle"
			x={x}
			y={y}
		>
			{value.split("\n").map((line, i) => (
				<tspan
					//
					key={i}
					dy={i === 0 ? "0" : "1.2em"}
					x={x}
				>
					{line}
				</tspan>
			))}
		</text>
	);
});
