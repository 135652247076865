import { AdCopyActivity } from "../../models/ad-copy/activity";
import { AdCopyConceptKey, AdCopyTestOnlyConceptKey } from "../../models/ad-copy/concept";
import { AdCopyJob } from "../../models/ad-copy/job";
import { AdCopyConceptTypeForDemand, SimulationSummaryData } from "../../models/ad-copy/output";

type CSVData = [string, number][];
type CSVDataWithEmpty = CSVData | [[]];

export class SimulationSummaryCSVDataMaker {
	constructor(
		protected activity: AdCopyActivity,
		protected job: AdCopyJob,
		protected simulationSummaryData: SimulationSummaryData,
	) {}

	public make(): CSVDataWithEmpty {
		const data = [
			["ジョブNo", this.simulationSummaryData.common.jobNum],
			...this.getBaseData("test1"),
			...this.getBaseData("test2"),
			...this.getBaseData("test3"),
			...this.getBaseData("currentProduct"),
			...this.getCurrentProducOnlyData(),
			...this.getTestOnlyData("test1"),
			...this.getTestOnlyData("test2"),
			...this.getTestOnlyData("test3"),
			...this.getCommonDataAndBenchMarkData(),
		].filter((d) => d.length === 2) as CSVDataWithEmpty;
		return data;
	}

	protected getPrefix(concept: AdCopyConceptKey): string {
		switch (concept) {
			case "test1":
				return "テスト品1";
			case "test2":
				return "テスト品2";
			case "test3":
				return "テスト品3";
			case "currentProduct":
				return "現行品";
			case "benchmark":
				return "競合品";
		}
	}

	protected getBaseData(concept: AdCopyConceptTypeForDemand): CSVDataWithEmpty {
		const data = this.simulationSummaryData[concept];
		if (data == null) return [[]];
		const prefix = this.getPrefix(concept);
		return [
			[`【${prefix}】ブランドユーザ購入意向: Top Box(%)`, data.brandUserPurchaseIntentionsTopBox],
			[`【${prefix}】ブランドユーザ購入意向: 2nd Box(%)`, data.brandUserPurchaseIntentionsSecondBox],
			[`【${prefix}】ブランドノンユーザ購入意向: Top Box(%)`, data.brandNonuserPurchaseIntentionsTopBox],
			[`【${prefix}】ブランドノンユーザ購入意向: 2nd Box(%)`, data.brandNonuserPurchaseIntentionsSecondBox],
			[`【${prefix}】ブランドユーザー購入意向ワンナンバースコア`, data.brandUserPurchaseIntentionsScore],
			[`【${prefix}】ブランドノンユーザー購入意向ワンナンバースコア`, data.brandNonuserPurchaseIntentionsScore],
			[`【${prefix}】カテゴリノンユーザー数(百万人)`, data.categoryNonuser],
			[`【${prefix}】ブランドユーザー数(百万人)`, data.brandUser],
			[`【${prefix}】ブランドノンユーザー数(百万人)`, data.brandNonuser],
			[`【${prefix}】想定商品認知率(%)`, data.goalRecognitionRate],
			[`【${prefix}】ブランドユーザの想定商品認知率(%)`, data.brandUserRecognitionRate],
			[`【${prefix}】ブランドノンユーザの想定商品認知率(%)`, data.brandNonuserRecognitionRate],
			[`【${prefix}】カテゴリノンユーザの想定商品認知率(%)`, data.categoryNonuserRecognitionRate],
			[`【${prefix}】ブランドユーザのトライアル購入数量 (万個)`, data.brandUserTrialSalesVolume],
			[`【${prefix}】ブランドノンユーザのトライアル購入数量 (万個)`, data.brandNonuserTrialSalesVolume],
			[`【${prefix}】カテゴリノンユーザのトライアル購入数量 (万個)`, data.categoryNonuserTrialSalesVolume],
			[`【${prefix}】トライアル購入数量(現行品補正前) (万個)`, data.trialSalesVolumeBeforeCorrection],
		];
	}

	protected getCurrentProducOnlyData(): CSVDataWithEmpty {
		const data = this.simulationSummaryData["currentProduct"];
		if (data == null) return [[]];
		return [["【現行品】実際のトライアル売上数量 (万個)", data.currentProductTrialSalesVolume]];
	}

	protected getTestOnlyData(concept: AdCopyTestOnlyConceptKey): CSVDataWithEmpty {
		const data = this.simulationSummaryData[concept];
		if (data == null) return [[]];
		const prefix = this.getPrefix(concept);
		return [
			[`【${prefix}】トライアル購入数量(現行品補正後) (万個)`, data.trialSalesVolume],
			[`【${prefix}】発売12か月の購入者あたり購入個数（万個）`, data.gRepeat],
			[`【${prefix}】ローカルその他比率(%)　※SLI/SCI申告漏れ補正前`, data.gRatio_LocalOther],
			[`【${prefix}】IB比率(%)　※SLI/SCI申告漏れ補正前`, data.gRatio_IBBY],
			[`【${prefix}】トライアル(万個)`, data.trialSalesVolumeAddUnreported],
			[`【${prefix}】リピート(万個)`, data.repeatSalesVolumeAddUnreported],
			[`【${prefix}】ローカルその他（万個）`, data.localOtherSalesVolume],
			[`【${prefix}】IB(万個)`, data.inboundBuyerSalesVolume],
			[`【${prefix}】合計数量(万個)`, data.potentialSalesVolume],
			[`【${prefix}】トライアル＋リピート (万個)`, data.totalOfTrialSalesVolumeAndRepeatSalesVolume],
		];
	}

	protected getCommonDataAndBenchMarkData(): CSVDataWithEmpty {
		const common = this.simulationSummaryData["common"];
		const csvData = [
			[`目標値 (万個)`, common.goalSalesVolume],
			...this.getStrategyTargetOnsScore(),
			...this.getStrategyTargetSampleSize(),
			[`申告漏れ係数`, common.gUnreport],
			[`戦略ターゲット出現率(%)`, common.appearanceByStrategyTarget],
		] as CSVDataWithEmpty;
		return csvData;
	}

	protected getStrategyTargetOnsScore(): CSVDataWithEmpty {
		const getData = (concept: AdCopyConceptKey) => {
			const d = this.simulationSummaryData[concept];
			if (d == null) return [];
			const prefix = this.getPrefix(concept);
			return [`戦略ターゲットG ${prefix} 購入意向ワンナンバースコア`, d.strategyTargetPurchaseIntentionsScore];
		};
		return [
			getData("test1"),
			getData("test2"),
			getData("test3"),
			getData("benchmark"),
			getData("currentProduct"),
		].filter((arr) => arr.length === 2) as CSVDataWithEmpty;
	}

	protected getStrategyTargetSampleSize(): CSVDataWithEmpty {
		const getData = (concept: AdCopyConceptKey) => {
			const d = this.simulationSummaryData[concept];
			if (d == null) return [];
			const prefix = this.getPrefix(concept);
			return [`戦略ターゲットG ${prefix} サンプルサイズ`, d.strategyTargetSampleSize];
		};
		return [
			getData("test1"),
			getData("test2"),
			getData("test3"),
			getData("benchmark"),
			getData("currentProduct"),
		].filter((arr) => arr.length === 2) as CSVDataWithEmpty;
	}
}
