import { useState, useEffect } from "react";
import { get } from "../lib/request";
import { Info } from "../../../server/models/info";
import { GetAdCopyInfoResponse } from "../../../server/types/request/ad-copy/info";
import { adCopyEndpoint } from "../../../server/router/ad-copy/endpoint";

const useAdCopyInfo = (): GetAdCopyInfoResponse => {
	const [data, setData] = useState(null as GetAdCopyInfoResponse);

	useEffect(() => {
		get<GetAdCopyInfoResponse>(`${adCopyEndpoint.info}`).then((response) => {
			if (response.data != null) {
				//dispatch({ type: "loadInfo", payload: response.data });
				setData(response.data);
			}

			if (response.data.message == null) {
				setData({
					message: {
						title: "",
						content: "",
						timestamp: new Date(2023, 7, 7),
					} as Info,
				} as GetAdCopyInfoResponse);
			}
		});
	}, []);

	return data;
};

export default useAdCopyInfo;
