import * as React from "react";

type Props = {
	dominantBaseline?: string;
	fill?: string;
	fontSize?: string;
	fontWeight?: number | string;
	textAnchor?: string;
	textDecoration?: string;
	value: number | string;
	writingMode?: string;
	x: number;
	y: number;
};

export const Text = React.memo((props: Props) => {
	const { dominantBaseline = "central", textAnchor = "middle", value, ...textProps } = props;

	return (
		<text
			//
			{...textProps}
			dominantBaseline={dominantBaseline}
			textAnchor={textAnchor}
		>
			{typeof value === "number" ? value.toFixed(2) : value}
		</text>
	);
});
