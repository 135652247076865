import { SimulationBaseValue } from "../../models/ad-copy/output/simulationBaseData";
import { AdCopyCorrectionValue } from "../../models/ad-copy/simulation-value";
import { AdCopyCorrectedValue } from "../../models/ad-copy/cp";
import {
	calcCurrentProductTrialSalesVolume,
	calcIBBYVolume,
	calcLocalOther,
	calcPurchaseIntentBrandNonuser,
	calcPurchaseIntentBrandUser,
	calcRatioToTargetSalesVolume,
	calcRecognitionRateByBrand,
	calcRecognitionRateByCategory,
	calcRepeatSaleVolume,
	calcTrialRate,
	calcTrialSalesVolume,
	calcTrialSalesVolumeBeforeCorrection,
	calcTrialSalesVolumeByTarget,
	calcUnreport,
} from "./baseCalculator";
import { isDefined } from "../../lib/common";

/**
 * 各テスト品の補整値を加味した推定値を算出する
 */
export class EstimatedValueCalculator {
	constructor(
		protected simulationBaseValue: SimulationBaseValue,
		protected correctionValue: AdCopyCorrectionValue,
		protected currentProductPurchaseRate?: number,
		protected trialSalesVolumeBeforeCorrectionByCurrentProduct?: number,
	) {}

	protected getRecognitionRate() {
		if (this.simulationBaseValue.isExistingBrand) {
			return calcRecognitionRateByBrand(
				this.simulationBaseValue.brandUserRate,
				this.simulationBaseValue.brandNonuserRate,
				this.simulationBaseValue.categoryNonuserRate,
				this.correctionValue.recognitionRate ?? this.simulationBaseValue.goalRecognitionRate,
			);
		} else {
			return calcRecognitionRateByCategory(
				this.simulationBaseValue.brandNonuserRate,
				this.simulationBaseValue.categoryNonuserRate,
				this.correctionValue.recognitionRate ?? this.simulationBaseValue.goalRecognitionRate,
			);
		}
	}

	/** ブランドユーザに置けるトライアル購入数量（補正前）の値を算出する */
	protected calcTrialSalesVolumeByBrandUser(): number {
		const purchaseIntent = calcPurchaseIntentBrandUser(
			this.simulationBaseValue.segment1PurchaseIntent,
			this.simulationBaseValue.segment2PurchaseIntent,
			this.simulationBaseValue.brandUserStrategyTargetRatio,
		);
		const recognitionRate = this.getRecognitionRate();
		const trialRate = calcTrialRate(
			purchaseIntent,
			recognitionRate.brandUser,
			this.simulationBaseValue.brandUserConstant,
		);
		return calcTrialSalesVolumeByTarget(this.simulationBaseValue.brandUser, trialRate);
	}

	/** ブランドノンユーザに置けるトライアル購入数量（補正前）の値を算出する */
	protected calcTrialSalesVolumeByBrandNonuser(): number {
		const purchaseIntent = calcPurchaseIntentBrandNonuser(
			this.simulationBaseValue.segment3PurchaseIntent,
			this.simulationBaseValue.segment4PurchaseIntent,
			this.simulationBaseValue.brandNonUserStrategyTargetRatio,
		);
		const recognitionRate = this.getRecognitionRate();
		const trialRate = calcTrialRate(
			purchaseIntent,
			recognitionRate.brandNonuser,
			this.simulationBaseValue.brandNonUserConstant,
		);
		return calcTrialSalesVolumeByTarget(this.simulationBaseValue.brandNonuser, trialRate);
	}

	/** カテゴリノンユーザに置けるトライアル購入数量（補正前）の値を算出する */
	protected calcTrialSalesVolumeByCategoryNonuser(): number {
		const purchaseIntent = calcPurchaseIntentBrandNonuser(
			this.simulationBaseValue.segment3PurchaseIntent,
			this.simulationBaseValue.segment4PurchaseIntent,
			this.simulationBaseValue.brandNonUserStrategyTargetRatio,
		);
		const recognitionRate = this.getRecognitionRate();
		const trialRate = calcTrialRate(
			purchaseIntent,
			recognitionRate.categoryNonuser,
			this.simulationBaseValue.categoryNonUserConstant,
		);
		return calcTrialSalesVolumeByTarget(this.simulationBaseValue.categoryNonuser, trialRate);
	}

	/**
	 * トライアル購入数量（補正後）の計算に必要な補整値を計算する
	 *  = [現行刺激トライアル売上数量] / [現行刺激のトライアル購入数量（補正前）]
	 * 現行刺激が存在しない場合は、`1`を返す
	 */
	protected calcCorrection(): number {
		const currentProductPurchaseRate = this.currentProductPurchaseRate;
		const beforeCorrection = this.trialSalesVolumeBeforeCorrectionByCurrentProduct;
		// 現行品が存在しないに相当する場合は補整なしを示す
		if (!isDefined(currentProductPurchaseRate) && !isDefined(beforeCorrection)) {
			return 1;
		}
		try {
			const currentProductTrialSalesVolume = calcCurrentProductTrialSalesVolume(
				currentProductPurchaseRate,
				this.simulationBaseValue.repPopulation,
			);
			return currentProductTrialSalesVolume / this.trialSalesVolumeBeforeCorrectionByCurrentProduct;
		} catch (e) {
			console.error(
				`invalid data! "currentProductPurchaseRate": ${currentProductPurchaseRate}, "beforeCorrection": ${beforeCorrection}`,
			);
		}
	}

	/** 推定値の計算 */
	public calcEstimatedValue(): AdCopyCorrectedValue {
		const trialSalesVolumeByBrandUser = this.calcTrialSalesVolumeByBrandUser();
		const trialSalesVolumeByBrandNonuser = this.calcTrialSalesVolumeByBrandNonuser();
		const trialSalesVolumeByCategoryNonuser = this.calcTrialSalesVolumeByCategoryNonuser();
		const trialSalesVolumeBeforeCorrection = calcTrialSalesVolumeBeforeCorrection(
			trialSalesVolumeByBrandUser,
			trialSalesVolumeByBrandNonuser,
			trialSalesVolumeByCategoryNonuser,
		);
		const correction = this.calcCorrection();
		const trialSalesVolume = calcTrialSalesVolume(trialSalesVolumeBeforeCorrection, correction);
		const repeatSalesVolume = calcRepeatSaleVolume(
			trialSalesVolume,
			this.correctionValue.purchaseQuantity ?? this.simulationBaseValue.gRepeat,
		);
		const trialUnreportedSalesVolume = calcUnreport(trialSalesVolume, this.simulationBaseValue.gUnreport);
		const repeatUnreportedSalesVolume = calcUnreport(repeatSalesVolume, this.simulationBaseValue.gUnreport);
		const localOtherSalesVolume = calcLocalOther(
			trialSalesVolume,
			repeatSalesVolume,
			this.correctionValue.localRatio ?? this.simulationBaseValue.gRatioLocalOther,
		);
		const inboundBuyerSalesVolume = calcIBBYVolume(
			trialSalesVolume,
			repeatSalesVolume,
			this.correctionValue.inboundRatio ?? this.simulationBaseValue.gRatioIBBY,
		);
		const trial = trialSalesVolume + trialUnreportedSalesVolume;
		const repeat = repeatSalesVolume + repeatUnreportedSalesVolume;
		const total = trial + repeat + localOtherSalesVolume + inboundBuyerSalesVolume;
		return {
			trial,
			repeat,
			localOther: localOtherSalesVolume,
			inbound: inboundBuyerSalesVolume,
			total,
			toTargetSalesVolume: calcRatioToTargetSalesVolume(total, this.simulationBaseValue.goalSalesVolume),
		};
	}
}
