import * as React from "react";

import type { AdCopyCorrectedValue } from "../../../../../../../../../server/models/ad-copy/cp";
import { AdCopyResultTableRow } from "./ad-copy-result-table-row";

// 推定値テーブル

type Props = {
	cpValues: AdCopyCorrectedValue;
	hasCurrentProduct: boolean;
	initialValues: Required<AdCopyCorrectedValue>;
	testValues: Partial<AdCopyCorrectedValue>;
};

export const AdCopyResultTable = React.memo((props: Props) => {
	const { cpValues, hasCurrentProduct, initialValues, testValues } = props;

	return (
		<table className="result-table">
			<thead>
				<tr>
					<th className="subtitle" rowSpan={2}>
						推定値
					</th>

					<th rowSpan={2}>初期値(万個)</th>

					<th colSpan={2}>①実績値 修正後</th>

					<th colSpan={2}>②テスト品 変数補整後</th>
				</tr>

				<tr>
					<th>補整値(万個)</th>

					<th>補整率(%)</th>

					<th>補整値(万個)</th>

					<th>補整率(%)</th>
				</tr>
			</thead>

			<tbody>
				<AdCopyResultTableRow
					cpValue={cpValues.trial}
					hasCurrentProduct={hasCurrentProduct}
					initialValue={initialValues.trial}
					label="トライアル"
					name="trial"
					testValue={testValues.trial}
				/>

				<AdCopyResultTableRow
					cpValue={cpValues.repeat}
					hasCurrentProduct={hasCurrentProduct}
					initialValue={initialValues.repeat}
					label="リピート"
					name="repeat"
					testValue={testValues.repeat}
				/>

				<AdCopyResultTableRow
					cpValue={cpValues.localOther}
					hasCurrentProduct={hasCurrentProduct}
					initialValue={initialValues.localOther}
					label="ローカルその他"
					name="localOther"
					testValue={testValues.localOther}
				/>

				<AdCopyResultTableRow
					cpValue={cpValues.inbound}
					hasCurrentProduct={hasCurrentProduct}
					initialValue={initialValues.inbound}
					label="インバウンド"
					name="inbound"
					testValue={testValues.inbound}
				/>

				<AdCopyResultTableRow
					cpValue={cpValues.total}
					hasCurrentProduct={hasCurrentProduct}
					initialValue={initialValues.total}
					label="合計"
					name="total"
					testValue={testValues.total}
				/>

				{/* NOTE: コンセプトテストのやり方。コピーテストでは、目標対比もserver側の関数を使用して算出することとする */}
				{/* <AdCopyResultTableRow
					correctionValue={
						correctionValues?.total == null ? undefined : (correctionValues.total / goalSalesVolume) * 100 - 100
					}
					initValue={(initValues.total / goalSalesVolume) * 100 - 100}
					label="目標対比(%)"
					name="goal"
					targetValue={targetValues?.total == null ? undefined : (targetValues.total / goalSalesVolume) * 100 - 100}
				/> */}

				<AdCopyResultTableRow
					cpValue={cpValues.toTargetSalesVolume}
					hasCurrentProduct={hasCurrentProduct}
					initialValue={initialValues.toTargetSalesVolume}
					label="目標対比(%)"
					name="goal"
					testValue={testValues.toTargetSalesVolume}
				/>
			</tbody>
		</table>
	);
});
