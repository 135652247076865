import { type ClientEndpoint, type ClientEndpointPath, clientEndpoint } from "./endpoint";

export const adCopyRoot = "/ad-copy";

export const adCopyClientEndpoint = {
	activity: `${adCopyRoot}${clientEndpoint.activity}`,

	activityCreate: `${adCopyRoot}${clientEndpoint.activityCreate}`,

	activityStatus: `${adCopyRoot}${clientEndpoint.activityStatus}`,
	job: `${adCopyRoot}${clientEndpoint.job}`,
	jobSearch: `${adCopyRoot}${clientEndpoint.jobSearch}`,
	jobDownload: `${adCopyRoot}${clientEndpoint.jobDownload}`,
	jobCreate: `${adCopyRoot}${clientEndpoint.jobCreate}`,
	jobCreateRetest: `${adCopyRoot}${clientEndpoint.jobCreateRetest}`,

	jobDetail: `${adCopyRoot}${clientEndpoint.jobDetail}`,
	invoice: `${adCopyRoot}${clientEndpoint.invoice}`,
	concept: `${adCopyRoot}${clientEndpoint.concept}`,
	conceptPreview: `${adCopyRoot}${clientEndpoint.conceptPreview}`,
	quota: `${adCopyRoot}${clientEndpoint.quota}`,
	application: `${adCopyRoot}${clientEndpoint.application}`,
	approval: `${adCopyRoot}${clientEndpoint.approval}`,
	preview: `${adCopyRoot}${clientEndpoint.preview}`,

	// レポートページ
	outline: `${adCopyRoot}${clientEndpoint.outline}`,
	simulationReport: `${adCopyRoot}${clientEndpoint.simulationReport}`,
	conceptReport: `${adCopyRoot}${clientEndpoint.conceptReport}`,

	downloadReport: `${adCopyRoot}${clientEndpoint.downloadReport}`,

	resultByTargetReport: `${adCopyRoot}${clientEndpoint.resultByTargetReport}`,
	oaReport: `${adCopyRoot}${clientEndpoint.oaReport}`,
	keyMeasurementsReport: `${adCopyRoot}${clientEndpoint.keyMeasurementsReport}`,

	// ノームフラグ管理ページ
	normFlags: `${adCopyRoot}${clientEndpoint.normFlags}`,
	infoEditPage: `${adCopyRoot}${clientEndpoint.infoEditPage}`,
} as const satisfies Partial<Record<ClientEndpoint, `${typeof adCopyRoot}${ClientEndpointPath}`>>;

export type AdCopyClientEndpoint = keyof typeof adCopyClientEndpoint;

export type AdCopyClientEndpointPath = (typeof adCopyClientEndpoint)[AdCopyClientEndpoint];
