import * as React from "react";

import { Text } from "./text";

type Props = {
	color: string;
	fontSize?: string;
	fontWeight?: "bold";
	height: number;
	value?: number | string;
	width: number;
	x: number;
	y: number;
};

export const Bar = React.memo((props: Props) => {
	const { color, fontWeight, height, value, width, x, y, ...textProps } = props;

	return (
		<g>
			<rect
				height={height}
				style={{
					fill: color,
					opacity: 0.4,
				}}
				width={width}
				x={x}
				y={y}
			/>

			{value && (
				<Text
					fontWeight={fontWeight}
					value={typeof value === "number" ? value.toFixed(2) : value}
					x={x + width / 2}
					y={y + height / 2}
					{...textProps}
				/>
			)}
		</g>
	);
});
