export type SimulationSummaryData = {
	common: SimulationSummaryDataByCommon;
	test1: SimulationSummaryDataByTest;
	test2?: SimulationSummaryDataByTest;
	test3?: SimulationSummaryDataByTest;
	currentProduct?: SimulationSummaryDataByCurrentProduct;
	benchmark?: SimulationSummaryDataByBenchMark;
};

/** テスト品/現行品で重複して値を出す必要性のないもの */
export type SimulationSummaryDataByCommon = {
	/** jobNum */
	jobNum: string;
	/** 目標値 (万個) */
	goalSalesVolume: number;
	/** 申告漏れ係数 */
	gUnreport: number;
	/** 戦略ターゲット出現率 */
	appearanceByStrategyTarget: number;
};

/** テスト品/現行品で共通のプロパティ */
export type SimulationSummaryBaseData = {
	/** ブランドユーザ購入意向: Top Box(%) */
	brandUserPurchaseIntentionsTopBox?: number;
	/** ブランドユーザ購入意向: 2nd Box(%) */
	brandUserPurchaseIntentionsSecondBox?: number;
	/** ブランドノンユーザ購入意向: Top Box(%) */
	brandNonuserPurchaseIntentionsTopBox: number;
	/** ブランドノンユーザ購入意向: 2nd Box(%) */
	brandNonuserPurchaseIntentionsSecondBox: number;
	/** ブランドユーザー購入意向ワンナンバースコア */
	brandUserPurchaseIntentionsScore?: number;
	/** ブランドノンユーザー購入意向ワンナンバースコア */
	brandNonuserPurchaseIntentionsScore: number;
	/** カテゴリノンユーザー数(百万人) */
	categoryNonuser: number;
	/** ブランドユーザー数(百万人) */
	brandUser?: number;
	/** ブランドノンユーザー数(百万人) */
	brandNonuser: number;
	/** 想定認知率(%) */
	goalRecognitionRate?: number;
	/** ブランドユーザ認知率 */
	brandUserRecognitionRate?: number;
	/** ブランドノンユーザ認知率 */
	brandNonuserRecognitionRate?: number;
	/** カテゴリノンユーザ認知率 */
	categoryNonuserRecognitionRate?: number;
	/** ブランドユーザのトライアル購入数量 (万個) */
	brandUserTrialSalesVolume?: number;
	/** ブランドノンユーザのトライアル購入数量 (万個) */
	brandNonuserTrialSalesVolume: number;
	/** カテゴリノンユーザのトライアル購入数量 (万個) */
	categoryNonuserTrialSalesVolume: number;
	/** トライアル購入数量(現行品補正前) (万個) */
	trialSalesVolumeBeforeCorrection: number;
	/** 戦略ターゲットG 購入意向ワンナンバースコア */
	strategyTargetPurchaseIntentionsScore?: number;
	/** 戦略ターゲットG サンプルサイズ */
	strategyTargetSampleSize?: number;
};

/** テスト品で必要になるプロパティ */
export type SimulationSummaryDataByTest = SimulationSummaryBaseData & {
	/** トライアル購入数量(現行品補正後) (万個) */
	trialSalesVolume: number;
	/** 発売12か月の購入者あたり購入個数（万個） */
	gRepeat: number;
	/** ローカルその他比率(%)　※SLI/SCI申告漏れ補正前 */
	gRatio_LocalOther: number;
	/** IB比率(%)　※SLI/SCI申告漏れ補正前 */
	gRatio_IBBY: number;
	/** トライアル(万個) */
	trialSalesVolumeAddUnreported: number;
	/** リピート(万個) */
	repeatSalesVolumeAddUnreported: number;
	/** ローカルその他（万個） */
	localOtherSalesVolume: number;
	/** IB(万個) */
	inboundBuyerSalesVolume: number;
	/** 合計数量(万個) */
	potentialSalesVolume: number;
	/** トライアル＋リピート (万個) */
	totalOfTrialSalesVolumeAndRepeatSalesVolume: number;
};

/** 現行品で必要になるプロパティ */
export type SimulationSummaryDataByCurrentProduct = SimulationSummaryBaseData & {
	/** 【現行品】実際のトライアル売上数量 (万個) */
	currentProductTrialSalesVolume?: number;
};

/** 競合品で必要になるプロパティ */
export type SimulationSummaryDataByBenchMark = Pick<
	SimulationSummaryBaseData,
	"strategyTargetPurchaseIntentionsScore" | "strategyTargetSampleSize"
>;
