import * as React from "react";
import styled from "styled-components";

import type { AdCopyActivityWithBrand } from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopyCP } from "../../../../../../../../server/models/ad-copy/cp";
import type { AdCopyJob } from "../../../../../../../../server/models/ad-copy/job";
import type { AdCopyCorrectionValue } from "../../../../../../../../server/models/ad-copy/simulation-value";
import type { ErrorObject } from "../../../../../../../../server/types/error";
import type { AdCopySimulationBrandData } from "../../../../../../../../server/types/request/ad-copy/report/simulation";
import { AdCopyCPParameterSetting } from "./parameter";
import { AdCopyCPSetting } from "./setting";

const WrapArea = styled.div`
	margin: 0.8rem;
`;

export type AdCopyCPPageProps = {
	activity: AdCopyActivityWithBrand;
	constValue: AdCopySimulationBrandData;
	cp: Partial<AdCopyCP>;
	errors: ErrorObject | undefined;
	hasEditRole: boolean;
	job: AdCopyJob;
	maxRecognition: number;
	onChange: <K extends keyof AdCopyCP>(key: K, value: Partial<AdCopyCP>[K]) => void;
	onClear: () => void;
	onSubmit: () => void;
	onSubmitCPCorrectionValues: () => void;
};

export const AdCopyCPPage = React.memo(
	({
		activity,
		constValue,
		cp,
		errors,
		hasEditRole,
		job,
		maxRecognition,
		onChange,
		onClear,
		onSubmit,
		onSubmitCPCorrectionValues,
	}: AdCopyCPPageProps) => {
		const onChangeRatio = React.useCallback(
			<K extends "recognitionRate" | "recognitionRateReason">(name: K, value: AdCopyCorrectionValue[K]) => {
				onChange("correctionValues", {
					...(cp.correctionValues ?? {}),
					[name]: value,
				} as any);
			},
			[cp, onChange],
		);

		return (
			<WrapArea>
				{/* 現行品/類似品の購入率補整 */}
				<AdCopyCPSetting
					cp={cp}
					errors={errors}
					hasEditRole={hasEditRole}
					job={job}
					onChange={onChange}
					onClear={onClear}
					onSubmit={onSubmit}
				/>

				{/* 現行品/類似品の商品認知率補整 */}
				<AdCopyCPParameterSetting
					constValue={constValue}
					cp={cp}
					hasEditRole={hasEditRole}
					isExistingBrand={activity.isExistingBrand ?? false}
					job={job}
					maxRecognition={maxRecognition}
					onChangeRatio={onChangeRatio}
					onSubmit={onSubmitCPCorrectionValues}
				/>
			</WrapArea>
		);
	},
);
