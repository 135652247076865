import * as React from "react";
import { Col, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";

import { Datetimepicker } from "../../../../../../../react-components/lu-component/src/components/datetime-picker";
import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import type { ErrorObject } from "../../../../../../../server/types/error";
import { canSetDemandForecast } from "../../../../../lib/ad-copy/activity";
import { getErrorMessage } from "../../../../../lib/error";
import { AdCopyRadios } from "../../../../parts/ad-copy/radios";
import { AlertText } from "../../../../parts/font";
import { CurrentProductRadios } from "./current-product-radios";

type Props = {
	adCopyCanDemandForecastNeeded: boolean;
	errors: ErrorObject | undefined;
	job: Partial<AdCopyJob>;
	needCurrentProduct: boolean;
	onChange: <T extends keyof AdCopyJob>(name: T, value: AdCopyJob[T]) => void;
};

export const JobForm = React.memo((props: Props) => {
	const { adCopyCanDemandForecastNeeded, errors, job, needCurrentProduct, onChange } = props;

	const isRetest = !!job.retestJobId;

	const shouldShowCurrentProductForms =
		(job.isDemandForecastNeeded ?? false) &&
		(job.hasCurrentProduct ||
			(isRetest &&
				job.currentProductPurchaseRate != null &&
				job.currentProductPurchaseRateReason != null &&
				job.currentProductRecognitionRate != null));

	return (
		<>
			<Row>
				<FormGroup as={Col}>
					<FormLabel>ベンチマーク</FormLabel>
				</FormGroup>
			</Row>

			{/* 現行品有無 */}
			<Row>
				<Col
					style={{
						marginLeft: "10px",
					}}
				>
					<CurrentProductRadios
						errors={errors}
						hasCurrentProduct={job.hasCurrentProduct}
						hasSimilarProduct={job.hasSimilarProduct}
						isRetest={isRetest}
						needCurrentProduct={needCurrentProduct}
						onChange={onChange}
					/>
				</Col>
			</Row>

			{/* 競合品有無 */}
			<Row>
				{(() => {
					const name = "hasBenchmark";

					return (
						<FormGroup
							as={Col}
							md={4}
							style={{
								marginLeft: "10px",
							}}
						>
							<FormLabel>競合品</FormLabel>

							<AdCopyRadios
								checked={job[name]}
								choices={[
									{ label: "あり", value: true },
									{ label: "なし", value: false },
								]}
								disabled={isRetest}
								name={name}
								onChange={(v) => {
									onChange(name, v);
								}}
							/>

							{errors && name in errors && (
								<Feedback
									style={{
										display: "block",
									}}
									type="invalid"
								>
									{getErrorMessage(name, errors)}
								</Feedback>
							)}
						</FormGroup>
					);
				})()}

				{/* 競合品製品名 */}
				{(() => {
					const name = "benchmarkName";

					if (!job.hasBenchmark) {
						return;
					}

					return (
						<FormGroup
							//
							as={Col}
							md={5}
						>
							<FormLabel>競合品ありの場合はブランド名と商品名を入力してください</FormLabel>

							<FormControl
								disabled={isRetest}
								isInvalid={errors && name in errors}
								name={name}
								onChange={(e) => {
									onChange(name, e.target.value);
								}}
								required
								style={
									isRetest
										? {
												opacity: 0.5,
										  }
										: undefined
								}
								value={job[name] ?? ""}
							/>

							{errors && name in errors && (
								<Feedback
									style={{
										display: "block",
									}}
									type="invalid"
								>
									{getErrorMessage(name, errors)}
								</Feedback>
							)}
						</FormGroup>
					);
				})()}
			</Row>

			{/* 自社テスト品 */}
			<Row>
				{(() => {
					const name = "numOfTests";

					return (
						<FormGroup as={Col}>
							<FormLabel>自社テスト品</FormLabel>

							<AdCopyRadios
								checked={job[name]}
								choices={[
									{ label: "1案", value: 1 },
									{ label: "2案", value: 2 },
									{ label: "3案", value: 3 },
								]}
								name={name}
								onChange={(v) => {
									onChange(name, v);
								}}
							/>

							{errors && name in errors && (
								<Feedback
									style={{
										display: "block",
									}}
									type="invalid"
								>
									{getErrorMessage(name, errors)}
								</Feedback>
							)}
						</FormGroup>
					);
				})()}
			</Row>

			{/* キービジュアル有無 */}
			<Row>
				{(() => {
					const name = "hasKeyVisual";

					return (
						<FormGroup as={Col}>
							<FormLabel>呈示物パターン</FormLabel>

							<AdCopyRadios
								checked={job[name]}
								choices={[
									{ label: "キービジュアル（KV）なし", value: false },
									{ label: "キービジュアル（KV）あり", value: true },
								]}
								disabled={isRetest}
								name={name}
								onChange={(v) => {
									onChange(name, v);
								}}
							/>

							{errors && name in errors && (
								<Feedback
									style={{
										display: "block",
									}}
									type="invalid"
								>
									{getErrorMessage(name, errors)}
								</Feedback>
							)}
						</FormGroup>
					);
				})()}
			</Row>

			<Row>
				{/* 需要予測の有無 */}
				{(() => {
					const name = "isDemandForecastNeeded";

					if (!(canSetDemandForecast && adCopyCanDemandForecastNeeded)) {
						return;
					}

					return (
						<FormGroup as={Col}>
							<FormLabel>
								需要予測の有無
								{job.hasCurrentProduct != null && !job.hasCurrentProduct && job.isDemandForecastNeeded && (
									<>
										<br />

										<AlertText>※現行品/類似品が設定されていないため、実績補整は反映されません</AlertText>
									</>
								)}
							</FormLabel>

							<AdCopyRadios
								checked={job[name]}
								choices={[
									{ label: "あり", value: true },
									{ label: "なし", value: false },
								]}
								disabled={isRetest}
								name={name}
								onChange={(v) => {
									onChange(name, v);
								}}
							/>

							{errors && name in errors && (
								<Feedback
									style={{
										display: "block",
									}}
									type="invalid"
								>
									{getErrorMessage(name, errors)}
								</Feedback>
							)}
						</FormGroup>
					);
				})()}
			</Row>

			{shouldShowCurrentProductForms && (
				<Row className="mt-3">
					{/* 現行品の想定認知率(%) */}
					{(() => {
						const name = "currentProductRecognitionRate";

						return (
							<FormGroup
								as={Col}
								md={5}
								style={{
									marginLeft: "10px",
								}}
							>
								<FormLabel>
									<span>現行品/類似品の</span>

									<br />

									<span>日本人（当該性の）15～69歳における</span>

									<br />

									<span>商品認知率（％）</span>
								</FormLabel>

								<FormControl
									disabled={isRetest}
									isInvalid={errors && name in errors}
									min={1}
									name={name}
									onChange={(e) => {
										const tmp = Number(e.target.value);

										if (isNaN(tmp)) {
											return;
										}

										onChange(name, Math.floor(tmp));
									}}
									pattern="\d+"
									style={
										isRetest
											? {
													opacity: 0.5,
											  }
											: undefined
									}
									type="text"
									value={job[name] || ""}
								/>

								{errors && name in errors && (
									<Feedback
										style={{
											display: "block",
										}}
										type="invalid"
									>
										{getErrorMessage(name, errors)}
									</Feedback>
								)}
							</FormGroup>
						);
					})()}

					<Col>
						<AlertText>※HP Trackingやコミュニケーションカルテ結果などを参照</AlertText>
					</Col>
				</Row>
			)}

			{shouldShowCurrentProductForms && (
				<Row className="mt-3">
					{/* 現行品/類似品の直近12か月の購入率(%) */}
					{(() => {
						const name = "currentProductPurchaseRate";

						return (
							<FormGroup
								as={Col}
								md={5}
								style={{
									marginLeft: "10px",
								}}
							>
								<FormLabel>
									現行品/類似品 の
									<br />
									直近12か月の購入率(%)
								</FormLabel>

								<FormControl
									disabled={isRetest}
									isInvalid={errors && name in errors}
									min={0}
									name={name}
									onChange={(e) => {
										const tmp = Number(e.target.value);

										if (isNaN(tmp)) {
											return;
										}

										onChange(name, tmp);
									}}
									step={0.01}
									style={
										isRetest
											? {
													opacity: 0.5,
											  }
											: undefined
									}
									type="number"
									value={job[name] ?? ""}
								/>

								{errors && name in errors && (
									<Feedback
										style={{
											display: "block",
										}}
										type="invalid"
									>
										{getErrorMessage(name, errors)}
									</Feedback>
								)}
							</FormGroup>
						);
					})()}

					<Col>
						<AlertText>※直近12か月の購入率をSLI/SCIから確認し入力</AlertText>

						<br />

						<AlertText>　→iCanvas SLI/SCIの出力が「0.19」の場合、0.19と入力</AlertText>

						<div
							className="border border-danger p-1"
							style={{
								fontSize: "0.8rem",
							}}
						>
							<AlertText>対象カテゴリー：コピーテスト品の対象カテゴリーを設定</AlertText>

							<br />

							<AlertText>集計期間：直近12か月を設定</AlertText>

							<br />

							<AlertText>フィルタ：年齢（15～69歳）、絞込み用使用者（本人使用+家族と共用）</AlertText>

							<br />

							<AlertText>
								レポートデザイン項目：表側（ブランド・品種・SKU）、表頭（期間全体）、指標（年齢ウェイトつき、購入率・人数（×1））
							</AlertText>
						</div>
					</Col>
				</Row>
			)}

			{/* 購入率(%)の定義 */}
			{shouldShowCurrentProductForms && (
				<Row>
					{(() => {
						const name = "currentProductPurchaseRateReason";

						return (
							<FormGroup
								as={Col}
								md={5}
								style={{
									marginLeft: "10px",
								}}
							>
								<FormLabel>購入率(%)の定義（対象期間など）を記載</FormLabel>

								<FormControl
									disabled={isRetest}
									isInvalid={errors && name in errors}
									name={name}
									onChange={(e) => {
										onChange(name, e.target.value);
									}}
									style={
										isRetest
											? {
													opacity: 0.5,
											  }
											: undefined
									}
									value={job[name] ?? ""}
								/>

								{errors && name in errors && (
									<Feedback
										style={{
											display: "block",
										}}
										type="invalid"
									>
										{getErrorMessage(name, errors)}
									</Feedback>
								)}
							</FormGroup>
						);
					})()}
				</Row>
			)}

			{/* 調査開始日時 */}
			<Row className="mt-3">
				{(() => {
					const name = "startDatetime";

					return (
						<FormGroup as={Col}>
							<FormLabel>
								<span>調査開始日時</span>

								<br />

								<AlertText>※ 調査開始から4日後が最長の終了予定日となります。</AlertText>
							</FormLabel>

							<Datetimepicker
								name={name}
								onChange={(value) => {
									onChange(name, value);
								}}
								value={job[name] ?? ""}
							/>

							{errors && name in errors && (
								<Feedback
									style={{
										display: "block",
									}}
									type="invalid"
								>
									{getErrorMessage(name, errors)}
								</Feedback>
							)}
						</FormGroup>
					);
				})()}
			</Row>
		</>
	);
});
