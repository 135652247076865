import * as React from "react";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";

import { AdCopySelectableButton } from "../../editable-text/selectable-button";

const WrapArea = styled.div`
	position: relative;
	text-align: center;
	padding: 2px;
	border: 1px solid #ddd;
`;

const ButtonArea = styled.div`
	position: absolute;
	background-color: white;
	padding: 2px;
	top: -20px;
	left: 90px;
	background-color: white;
	z-index: 20;
	border: 1px solid #ddd;
`;

type Props = {
	disabled: boolean;
	initValue: number;
	onChange: (value: number | undefined) => void;
	value: number | undefined;
};

export const PurchaseRateEditableText = React.memo((props: Props) => {
	const { disabled, initValue, onChange, value } = props;

	const [focus, setFocus] = React.useState(false);

	const [target, setTarget] = React.useState(false);

	const [viewValue, setViewValue] = React.useState(value ?? "-");

	const onChangeValue = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const v = e.target.value;

			setViewValue(v);

			const num = Number(v);

			if (v === "" || isNaN(num)) {
				onChange(undefined);

				return;
			}

			onChange(num);
		},
		[onChange],
	);

	const events = React.useMemo(
		() => [
			{
				event: () => {
					setTarget(false);

					setFocus(true);
				},
				label: "補整する",
			},
			{
				event: () => {
					setTarget(false);

					setViewValue(initValue);

					onChange(initValue);
				},
				label: "初期値から変更しない",
			},
		],
		[initValue, onChange],
	);

	if (disabled || focus) {
		return (
			<FormControl
				disabled={disabled}
				min={0}
				onBlur={() => {
					const num = Number(viewValue);

					setViewValue(viewValue === "" || isNaN(num) ? "-" : num);

					setFocus(false);
				}}
				onChange={onChangeValue}
				step={0.01}
				style={
					disabled
						? {
								opacity: 0.5,
						  }
						: undefined
				}
				type="number"
				value={viewValue}
			/>
		);
	}

	return (
		<WrapArea
			onClick={() => {
				if (!target) {
					setTarget(true);
				}
			}}
			style={
				viewValue === "-"
					? {
							backgroundColor: "#ddd",
					  }
					: {}
			}
		>
			{target && (
				<ButtonArea>
					<AdCopySelectableButton
						events={events}
						onClose={() => {
							setTarget(false);
						}}
					/>
				</ButtonArea>
			)}

			{viewValue}
		</WrapArea>
	);
});
