import * as React from "react";
import { Button, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { Role } from "../../../../../server/lib/permission/role";
import type { AccountWithoutPassword } from "../../../../../server/models/account";
import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../routes/endpoint";

export const MenuPage = (loginAccount: AccountWithoutPassword) => {
	const navigate = useNavigate();

	const hasAccountViewRole = React.useMemo(() => loginAccount && Role.hasAccountViewRole(loginAccount), [loginAccount]);

	return (
		<Card className="border-0 mx-auto my-5" style={{ maxWidth: "540px" }}>
			<div className="text-end">
				<Button
					onClick={() => {
						navigate(-1);
					}}
					variant="outline-secondary"
				>
					戻る
				</Button>
			</div>

			{/* ガイドライン */}
			<p className="h3 mt-3">■ガイドライン</p>

			<div>
				<Link target="_blank" to={clientEndpoint.guidelines}>
					コンセプトテスト　ガイドライン
				</Link>
			</div>

			{/* TODO: 202407 準備ができるまでは非表示 */}
			{/* <div className="mt-1">2.コピーテスト ガイドライン</div> */}

			{/* マニュアル */}
			<p className="h3 mt-4">■マニュアル</p>

			<div>コンセプトテスト・コピーテスト共通</div>

			<div>
				<Link target="_blank" to={clientEndpoint.manual}>
					・Testit操作マニュアル
				</Link>
			</div>

			<div className="mt-3">コンセプトテスト</div>

			<div>
				<Link target="_blank" to={clientEndpoint.simulationManual}>
					・コンセプトテスト　需要予測補整マニュアル
				</Link>
			</div>

			{/* TODO: 202407 準備ができるまでは非表示 */}
			{/* <div className="mt-1">2.コピーテスト 操作マニュアル</div> */}

			{/* <div className="mt-1">2.コピーテスト 需要予測補整マニュアル</div> */}

			{/* 管理メニュー */}
			{hasAccountViewRole && (
				<>
					<p className="h3 mt-4">■管理メニュー</p>

					<div>
						<Link to={clientEndpoint.account}>アカウント一覧</Link>
					</div>

					<div className="mt-1">
						<Link to={clientEndpoint.infoEditPage}>コンセプトテスト　お知らせ設定</Link>
					</div>
					<div className="mt-1">
						<Link to={adCopyClientEndpoint.infoEditPage}>コピーテスト　お知らせ設定</Link>
					</div>

					<div className="mt-1">
						<Link to={`${clientEndpoint.normFlags}?checked=false&limit=100&page=0`}>
							ノームフラグ管理（コンセプトテスト）
						</Link>
					</div>

					<div className="mt-1">
						<Link to={`${adCopyClientEndpoint.normFlags}?checked=false&limit=100&page=0`}>
							ノームフラグ管理（コピーテスト）
						</Link>
					</div>
				</>
			)}
		</Card>
	);
};
