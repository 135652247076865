import * as React from "react";
import { Loading } from "../../../../../../react-components/lu-component/src";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { reducer, initState } from "../../../../reducers/ad-copy/info";
import { GetAllInfoResponse, GetInfoResponse } from "../../../../../../server/types/request/info";
import { get, makeError, post, remove } from "../../../../lib/request";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { AdCopyInfoEditPage } from "../../../../components/pages/ad-copy/info/edit";

export const AdCopyInfoEditContainer = (): JSX.Element => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	React.useEffect(() => {
		get<GetAllInfoResponse>(`${adCopyEndpoint.infoAll}`)
			.then((response) => {
				dispatch({ type: "loadInfoAll", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);

	const onTitleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({ type: "updateMessageTitle", payload: e.target.value });
	}, []);

	const onContentChange = React.useCallback((content: string) => {
		dispatch({ type: "updateMessageContent", payload: content });
	}, []);

	const navigate = useNavigate();

	const onUpdateSuccessClose = React.useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const onCreateNewInfo = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });

		const request: Promise<AxiosResponse<GetInfoResponse>> = post(`${adCopyEndpoint.info}`, {
			title: state.message.title,
			content: state.message.content,
		});

		request
			.then((response) => {
				dispatch({ type: "updateMessage", payload: response.data.message });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [state.message]);

	const onClear = React.useCallback(() => {
		dispatch({ type: "clearMessageTitleAndContent", payload: undefined });
	}, []);

	const onReset = React.useCallback(() => {
		dispatch({ type: "resetMessageTitleAndContent", payload: undefined });
	}, []);

	const onSinglePastInfoDelete = React.useCallback((id: string) => {
		dispatch({ type: "changeLoading", payload: true });

		remove(`${adCopyEndpoint.info}/${id}`)
			.then(() => {
				dispatch({ type: "deleteSinglePastInfo", payload: id });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);

	const onDeleteSuccessClose = React.useCallback(() => {
		dispatch({ type: "changeMessageInfo", payload: { message: "", isSuccess: true } });
		dispatch({ type: "changeConfirmType", payload: undefined });
	}, []);

	const onCopy = React.useCallback((content: string) => {
		dispatch({ type: "updateMessageContent", payload: content });
	}, []);

	return (
		<>
			<Loading loading={state.loading} />
			{state.info.isSuccess ? (
				state.confirmType === "create" ? (
					<ConfirmBox info={state.info} titleLabel={"お知らせの更新"} onClose={onUpdateSuccessClose} />
				) : (
					<ConfirmBox info={state.info} titleLabel={"お知らせの削除"} onClose={onDeleteSuccessClose} />
				)
			) : (
				<ConfirmBox info={state.info} titleLabel={"処理"} />
			)}
			{!state.loading && (
				<AdCopyInfoEditPage
					title={state.message.title}
					content={state.message.content}
					pastInfos={state.messages.slice(1)}
					onCreateNewInfo={onCreateNewInfo}
					onTitleChange={onTitleChange}
					onContentChange={onContentChange}
					onClear={onClear}
					onCopy={onCopy}
					onReset={onReset}
					onSinglePastInfoDelete={onSinglePastInfoDelete}
				/>
			)}
		</>
	);
};
