import * as React from "react";

import { decimalCalc } from "../../../../../../../../../server/lib/ad-copy/aggregate/common";
import type { AdCopyCorrectedValue } from "../../../../../../../../../server/models/ad-copy/cp";
import { calcDiffPercent } from "../../common";

type Props = {
	cpValue: number | undefined;
	hasCurrentProduct: boolean;
	initialValue: number;
	label: string;
	name: keyof AdCopyCorrectedValue | "goal";
	testValue: number | undefined;
};

export const AdCopyResultTableRow = React.memo((props: Props) => {
	const { cpValue, hasCurrentProduct, initialValue, label, name, testValue } = props;

	const diff = cpValue == null ? NaN : calcDiffPercent(initialValue, cpValue);

	const diffTestValue = testValue == null ? NaN : calcDiffPercent(cpValue ?? initialValue, testValue);

	return (
		<tr>
			<td className={name}>{label}</td>

			{/* 初期値(万個) */}
			<td>{decimalCalc(initialValue, 10).toFixed(1)}</td>

			{/* ①実績値 修正後 */}

			{/* 補整値(万個) */}
			<td>{cpValue == null || !hasCurrentProduct ? "-" : decimalCalc(cpValue, 10).toFixed(1)}</td>

			{/* 補整率(%) */}
			{name === "goal" ? (
				<td />
			) : (
				<td className={diff < 0 ? "minus" : "plus"}>{cpValue == null || !hasCurrentProduct ? "-" : diff.toFixed(1)}</td>
			)}

			{/* ②テスト品 変数補整後 */}

			{/* 補整値(万個) */}
			<td>{testValue == null ? "-" : decimalCalc(testValue, 10).toFixed(1)}</td>

			{/* 補整率(%) */}
			{name === "goal" ? (
				<td />
			) : (
				<td className={diffTestValue < 0 ? "minus" : "plus"}>
					{isNaN(diffTestValue) ? "-" : diffTestValue.toFixed(1)}
				</td>
			)}
		</tr>
	);
});
