import * as React from "react";
import { Col, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";

import type { AdCopyCP } from "../../../../../../../../../server/models/ad-copy/cp";
import type { ErrorObject } from "../../../../../../../../../server/types/error";
import { getErrorMessage } from "../../../../../../../lib/error";
import { TextRed } from "../../../../../../parts/font";
import { PurchaseRateEditableText } from "./purchase-rate-editable-text";

const name = "actualValue";

type Name = typeof name;

type Props = {
	disabled: boolean;
	errors: ErrorObject | undefined;
	initialValue: number;
	onChange: (key: Name, value: Partial<AdCopyCP>[Name]) => void;
	value: number | undefined;
};

export const AdCopyCpActualValueTableInput = React.memo((props: Props) => {
	const { disabled, errors, initialValue, onChange, value } = props;

	return (
		<Row className="m-2">
			<Col className="text-end" lg={4}>
				<div
					style={{
						whiteSpace: "pre",
					}}
				>
					現行品/類似品 の<br />
					直近12か月の購入率(%)
				</div>
			</Col>

			<Col lg={3}>
				<table>
					<thead>
						<tr>
							<th
								style={{
									backgroundColor: "#ddd",
									border: "1px solid #bbb",
									padding: "2px",
									textAlign: "center",
									width: "100px",
								}}
							>
								初期値
							</th>

							<th
								style={{
									backgroundColor: "#ddd",
									border: "1px solid #bbb",
									padding: "2px",
									textAlign: "center",
									width: "100px",
								}}
							>
								補整値
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							{/* 初期値 */}
							<td
								style={{
									border: "1px solid #bbb",
									padding: "2px",
									textAlign: "center",
								}}
							>
								{initialValue}
							</td>

							{/* 補整値 */}
							<td
								style={{
									border: "1px solid #bbb",
									padding: "2px",
									textAlign: "center",
								}}
							>
								<PurchaseRateEditableText
									disabled={disabled}
									initValue={initialValue}
									onChange={(value) => {
										onChange(name, value);
									}}
									value={value}
								/>
							</td>
						</tr>
					</tbody>
				</table>

				{!!errors?.[name] && (
					<Feedback
						style={{
							display: "block",
						}}
						type="invalid"
					>
						{getErrorMessage(name, errors)}
					</Feedback>
				)}
			</Col>

			<Col lg={5}>
				<TextRed>※初期値は、ジョブ登録時において入力した</TextRed>

				<br />

				<TextRed>「直近12か月の購入率(%)　※SLI/SCIに基づく」の数値が表示されています</TextRed>
			</Col>
		</Row>
	);
});
